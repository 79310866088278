<template>
  <v-app>
    <div class="settingsPage">
      <div class="container">
        <h1>Subscribe</h1>
        <br />
        <br />
        <v-card
          class="mx-auto"
          max-width="500"
        >
        <v-form v-model="isFormValid">
          <v-card-text>
            <v-list
              subheader
              three-line
            >
              
              <v-list-tile>
                <v-list-tile-content>
                  <v-list-tile-title>Stay Informed</v-list-tile-title>
                  <v-list-tile-sub-title>heedi is just getting started.  Stay informed of future developments</v-list-tile-sub-title>
                </v-list-tile-content>
              </v-list-tile>
              <div v-if="showThankYou" class="thankYouMessage">
                Thank you for subscribing.  Now, go <router-link to="/routine">find a routine</router-link>
              </div> 
              <div v-else class="inputContainer">
                
                  <v-text-field
                    v-bind:key="'subscibe_key'"
                    ref="subscribe_ref"
                    label="enter email address"
                    :rules="emailRules"
                    clearable
                    type="text"
                    v-model="email">
                  </v-text-field>
              </div>
              <br />
            </v-list>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text :disabled="!isFormValid" @click="handleSubscribe">Subscribe</v-btn>
            
          </v-card-actions>

          </v-form>
        </v-card>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
      </div>
      <VueFooter :dark-text="true" />
    </div>
  </v-app>
</template>

<script>
  //import somethingModule from '@/store/something'
  import '@/plugins/vuetify'
  import VueFooter from '@/components/VueFooter'
  import feedbackModule from '@/store/feedback'
  import { mapActions } from 'vuex'

  export default {
    name: "group",
    components: {
      VueFooter,
      //ThoughtIntake
    },
    data () {
      return {
        
        textRules: [
          (v) => !!v || 'Input is required',
          (v) => (v && v.length <= 100) || 'Max 100 characters'
        ],
        emailRules: [ v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,4})+$/.test(v) || 'E-mail must be valid' ],
        email: null,
        isFormValid: false,
        showThankYou: false,


      }
    },
    methods: {
      ...mapActions('feedback', [
        'saveEmail'
      ]),
      async handleSubscribe () {
        if(this.email) {
          await this.saveEmail(this.email)
          this.showThankYou = true
        }
      }
    },
    beforeCreate () {
      //if(!this.$store.state.something) this.$store.registerModule('something', somethingModule)
      if(!this.$store.state.feedback) this.$store.registerModule('feedback', feedbackModule)
      // if(!this.$store.state.experience) this.$store.registerModule('experience', experienceModule)
    },
    created () {
      /* eslint-disable no-undef */
      gtag('config', 'G-H9RD8W4MLR', {'page_path': '/subscribe'});
    },
    mounted () {

      

    },
    updated () {

    },
    watch: {

    }
  }
</script>

<style scoped>

  .settingsPage {
    background-color: transparent;
  }
  .inputContainer {
    width: 70%;
    margin: 0 auto;
  }
  .thankYouMessage {
    text-align: center;
    margin: 0 auto;
  }
</style>
